'use client';

import { Button, Center, Header, Row, Typography } from '@kamona/components';
import { UnHome01, UnRefreshCcw01 } from '@kamona/icons-v2';

import { logError } from '@/lib/utils';

export default function GlobalError({
  error,
  reset,
}: Readonly<{
  error: Error & { digest?: string };
  reset: () => void;
}>) {
  // Log the error to newrelic
  logError('Application crashed with error ::', error);

  return (
    <html lang="en">
      <body>
        <Center className="w-full h-[100vh]">
          <Center
            direction="column"
            className="md:w-1/3 lg:w-1/4 gap-y-units-unit20"
          >
            <Header>Something went wrong</Header>
            <Typography>
              We hit a snag. Please retry loading the page.
            </Typography>
            <Row className="gap-units-unit12">
              <Button
                variant="ghost"
                size="small"
                className={{ root: '!pl-4' }}
                onClick={() => window.location.reload()}
                startIcon={<UnRefreshCcw01 />}
              >
                Reload
              </Button>
              <Button
                variant="outline"
                appearance="plain"
                size="small"
                className={{ root: '!pl-4' }}
                startIcon={<UnHome01 />}
                onClick={() => {
                  window.location.href = '/';
                }}
              >
                Go Home
              </Button>
            </Row>
          </Center>
        </Center>
      </body>
    </html>
  );
}
